import React, { useRef, useState } from "react";
import Layout from "../components/Layout";
import { Link } from "react-router-dom";
import { TiArrowRightOutline } from "react-icons/ti";
import { Tilt } from "react-tilt";
import CustonModal from "../components/modal/custon_modal";
import { MdOpenInNew } from "react-icons/md";
import {
  FaCss3Alt,
  FaHtml5,
  FaJsSquare,
  FaNodeJs,
  FaReact,
  FaSass,
} from "react-icons/fa";
import { PiLadderSimpleDuotone } from "react-icons/pi";
import { SiBlender } from "react-icons/si";
import { gsap, Power3 } from "gsap";

const projectsData = [
  {
    NomeProgetto: "FLOPRICE",
    Descrizione:
      "This is a multi-platform app currently in development phase. It will serve as an e-commerce monitoring application, available for both web and Android platforms.",
    Tag: ["#in work", "#multi platform", "#android", "#web"],
    Immagine: require("../assets/prog/floprice.png"),
    Lang: ["NodeJS", "React", "JavaScript", "HTML", "SCSS"],

    projectUrl: "/projects",
  },
  {
    NomeProgetto: "EXPLOR",
    Descrizione:
      "This is a remastered/remake of one of my old personal projects developed to hone my skills some time ago. Currently, it's in the restoration phase. It's an astronomy website where information about various planets and more will be provided.",
    Tag: ["#in work", "#web" , "#Front-End"],
    Lang: ["HTML", "SCSS", "React", "JavaScript"],

    Immagine: require("../assets/prog/explor.png"),
    projectUrl: "/progetto-2",
  },
  {
    NomeProgetto: "Sushi Samurai",
    Descrizione:
      "This is a remastered/remake of one of my old personal projects developed to hone my skills some time ago. Currently, it's in the restoration phase. It's an astronomy website where information about various planets and more will be provided.",
    Tag: ["#in work", "#web","#React", "#Front-End"],
    Lang: ["HTML", "SCSS", "React", "JavaScript"],

    Immagine: require("../assets/prog/sushi.png"),
    projectUrl: "https://sushisamurai.netlify.app/",
  },
  {
    NomeProgetto: "Automatic Control System for a Greenhouse",
    Descrizione:
      "The project consists of implementing an automatic control system for a greenhouse, which regulates irrigation, ventilation and lighting based on parameters such as soil moisture, temperature and ambient light. The system uses a PLC to manage the various sensors and actuators.",

    fulldesc:
      "The project consists of implementing an automatic control system for a greenhouse, which regulates irrigation, ventilation, and lighting based on parameters such as soil moisture, temperature, and ambient light. The system uses a PLC (Programmable Logic Controller) to manage various sensors and actuators. Objectives: Maintain optimal growing conditions with minimal human intervention. Efficiently use water and energy resources. System Components: PLC: Central unit controlling the system. Sensors: Soil Moisture Sensor: Capacitive soil moisture sensor, Temperature Sensor: DS18B20 digital temperature sensor, Light Sensor: LDR (Light Dependent Resistor). Actuators: Irrigation System: Water pumps and solenoid valves, Ventilation System: Exhaust fans, Lighting System: LED grow lights. Relays: Interface sensors and actuators with the PLC. Operation: Irrigation Control: Activates irrigation when soil moisture is low. Deactivates once moisture is adequate. Ventilation Control: Turns on fans when the temperature is high. Turns off fans when the temperature is within range. Lighting Control: Turns on LED lights when ambient light is low. Turns off lights when ambient light is sufficient. Programming: The PLC is programmed using Ladder Logic to automate these controls. Example Ladder Logic: Irrigation Control: LD SOIL_MOISTURE_LOW, OUT IRRIGATION_ON, LDN SOIL_MOISTURE_LOW, OUT IRRIGATION_OFF. Ventilation Control: LD TEMPERATURE_HIGH, OUT VENTILATION_ON, LDN TEMPERATURE_HIGH, OUT VENTILATION_OFF. Lighting Control: LD LIGHT_LEVEL_LOW, OUT LIGHTS_ON, LDN LIGHT_LEVEL_LOW, OUT LIGHTS_OFF. Benefits: Increased Efficiency: Reduces the need for manual intervention. Optimal Plant Growth: Maintains ideal conditions for plant health. Resource Conservation: Efficient use of water and energy. Conclusion: This automated greenhouse control system demonstrates how modern automation technology can enhance agricultural practices, promoting sustainability and efficiency.",

    Tag: ["#in work", "#electrotechnics", "#plc", "#ladder"],
    Lang: ["Ladder Logic", "React", "JavaScript", "SCSS", "Blender"],

    Immagine:
      // "https://i.pinimg.com/564x/62/e4/4a/62e44ad3bfd439404ef233aa79b11faf.jpg",
      require("../assets/prog/capolavoro.png"),

    projectUrl: "capolavoro",
  },

  // Aggiungi gli altri oggetti qui
];

const Projects = () => {
  const [progModal, setProgModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const projectsRef = useRef([]);

  const handleOpenProgModal = (project) => {
    setSelectedProject(project);
    setProgModal(true);
  };

  const handleCloseProgModal = () => {
    setProgModal(false);
    setSelectedProject(null);
  };

  // Mappa dei linguaggi alle loro icone
  const langIcons = {
    NodeJS: <FaNodeJs />,
    React: <FaReact />,
    HTML: <FaHtml5 />,
    CSS: <FaCss3Alt />,
    SCSS: <FaSass />,
    Blender: <SiBlender />,
    JavaScript: <FaJsSquare />,
    PLC: "PLC", // Aggiungi icone specifiche se disponibili
    "Ladder Logic": <PiLadderSimpleDuotone />, // Aggiungi icone specifiche se disponibili
  };




  const animateProjects = () => {
    projectsRef.current.forEach((project, index) => {
      gsap.fromTo(
        project,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          ease: Power3.easeOut,
          delay: index * 0.1, // Delay each project's animation
        }
      );
    });
  };

  return (
    <>
      {progModal && selectedProject && (
        <CustonModal
          onBgClick={handleCloseProgModal}
          closeBtn={handleCloseProgModal}
        >
          <img src={selectedProject.Immagine} alt="immagine progetto" />
          <h2>{selectedProject.NomeProgetto}</h2>
          <p>{selectedProject.Descrizione}</p>
          {/* <p>{selectedProject.fulldesc}</p> */}

          <div className="tag_list">
            {selectedProject.Tag.map((tag, index) => (
              <span className="tag" key={index}>
                {tag}
              </span>
            ))}
          </div>
          <div className="lang_list">
            <span>Languages & Softwares:</span>
            {selectedProject.Lang.map((lang, index) => (
              <span className="lang" key={index}>
                {langIcons[lang] ? langIcons[lang] : lang} {lang}
              </span>
            ))}
          </div>
          <Link
            target="_blank"
            to={selectedProject.projectUrl}
            // onClick={() => handleOpenProgModal(project)}
          >
            go to visit <TiArrowRightOutline />
          </Link>
        </CustonModal>
      )}

      <div className="projectsList" id="projects">
        <span>{"{All Projects}"}</span>
        {projectsData.map((project, index) => (
          <div className="project" key={index} ref={(el) => (projectsRef.current[index] = el)}>
            {index % 2 === 0 ? (
              <>
                <div className="project_img">
                  <img src={project.Immagine} alt="immagine progetto" />
                </div>
                <div className="project_details">
                  <h2>{project.NomeProgetto}</h2>
                  <p>{project.Descrizione}</p>
                  <div className="tag_list">
                    {project.Tag.map((tag, index) => (
                      <span className="tag" key={index}>
                        {tag}
                      </span>
                    ))}
                  </div>

                  <div style={{ display: "flex" }}>
                    <Link
                      // target="_blank"
                      to={project.projectUrl}
                      // onClick={() => handleOpenProgModal(project)}
                    >
                      go to visit <TiArrowRightOutline />
                    </Link>
                    <Link
                      // target="_blank"
                      // to={project.projectUrl}
                      onClick={() => handleOpenProgModal(project)}
                      style={{ marginLeft: "15px" }}
                    >
                      Details <MdOpenInNew />
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="project_details">
                  <h2>{project.NomeProgetto}</h2>
                  <p>{project.Descrizione}</p>
                  <div className="tag_list">
                    {project.Tag.map((tag, index) => (
                      <span className="tag" key={index}>
                        {tag}
                      </span>
                    ))}
                  </div>
                  <div style={{ display: "flex" }}>
                    <Link
                      // target="_blank"
                      to={project.projectUrl}
                      // onClick={() => handleOpenProgModal(project)}
                    >
                      go to visit <TiArrowRightOutline />
                    </Link>
                    <Link
                      // target="_blank"
                      // to={project.projectUrl}
                      onClick={() => handleOpenProgModal(project)}
                      style={{ marginLeft: "15px" }}
                    >
                      Details <MdOpenInNew />
                    </Link>
                  </div>
                </div>
                <div className="project_img">
                  <img src={project.Immagine} alt="immagine progetto" />
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default Projects;
