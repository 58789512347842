import React from "react";

const GG = () => {
  return (
    <div className="GGG">
      Gthe site is offline for maintenance. for info contact me us instagram:
      @__i_fer__
    </div>
  );
};

export default GG;
